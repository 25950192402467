import React, { createContext, useState, useCallback } from "react";
import config from "../config";

export const EnvironmentContext = createContext();

export const EnvironmentProvider = ({ children }) => {
  const [env, setEnv] = useState(config.env);

  const switchEnvironment = useCallback(
    (newEnv) => {
      // If no argument is provided, toggle between 'prod' and 'dev'
      if (newEnv === undefined) {
        newEnv = env === "prod" ? "dev" : "prod";
      }
      // Update the API route
      config.apiBaseUrl = config.apiBaseUrl.replace(/\/dev$/, ""); // Remove /dev if it exists
      if (newEnv === "dev" && !config.apiBaseUrl.endsWith("/dev")) {
        config.apiBaseUrl += "/dev";
      }
      // Update the environment
      config.env = newEnv;
      setEnv(newEnv);
    },
    [env]
  );

  return (
    <EnvironmentContext.Provider value={{ env, switchEnvironment }}>
      {children}
    </EnvironmentContext.Provider>
  );
};
