import React from "react";
import AttemptsWithCommands from "./AttemptsWithCommands";

function LLMCalls({ agent, googleAuthToken, currentView }) {
  return (
    <>
      <h2>
        {currentView === "observables" && "Observable Details"}
        {currentView === "outputs" && "Output Details"}
        {currentView === "errors" && "Error Details"}
        {currentView === "agents" && "LLM Calls and Actions"}
      </h2>
      <div className="llm-calls-container">
        <AttemptsWithCommands
          className="AttemptsWithCommands"
          agent={agent}
          googleAuthToken={googleAuthToken}
        />
      </div>
    </>
  );
}

export default LLMCalls;
