import React from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import PropTypes from "prop-types";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const GoogleAuth = ({ onLoginSuccess, onLoginFailure }) => {
  const handleLoginSuccess = (credentialResponse) => {
    const token = credentialResponse.credential;
    // Decode the JWT token to extract user information
    const decodedToken = jwtDecode(token);
    const email = decodedToken.email; // Extract email from decoded token

    // Check if the email domain is @qurrent.ai
    if (!email.endsWith("@qurrent.ai")) {
      // Display an error message using alert
      alert("Only @qurrent.ai email addresses are allowed.");
      onLoginFailure("Only @qurrent.ai email addresses are allowed.");
      return;
    }

    const userObject = {
      token: token,
      email: email,
    };
    // Store token in local storage
    localStorage.setItem("googleAuthToken", token);
    // Set the default headers for axios with the Google Auth token
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    onLoginSuccess(userObject);
  };

  const handleLoginFailure = () => {
    console.error("Google login failed");
    onLoginFailure("Login failed. Please try again.");
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className="login-page">
        <img
          src={process.env.PUBLIC_URL + "/qurrent.png"}
          alt="Qurrent Logo"
          className="qurrent-logo"
          style={{
            width: "70%",
            aspectRatio: "1 / 1",
            objectFit: "contain",
            marginBottom: "20px",
          }}
        />
        <h1 className="welcome-message" style={{ color: "white" }}>
          Team Viewer
        </h1>
        <br />
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={handleLoginFailure}
          useOneTap
          width="250"
          className="google-login-button"
        />
      </div>
    </GoogleOAuthProvider>
  );
};

GoogleAuth.propTypes = {
  onLoginSuccess: PropTypes.func.isRequired,
  onLoginFailure: PropTypes.func.isRequired,
};

export default GoogleAuth;
