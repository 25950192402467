import React, { useContext } from "react";
import { EnvironmentContext } from "../contexts/EnvironmentContext";

const EnvSwitcher = ({ onEnvironmentSwitch }) => {
  const { env, switchEnvironment } = useContext(EnvironmentContext);

  const isProd = env === "prod";
  const buttonText = isProd ? "Switch to Development" : "Switch to Production";
  const buttonClass = `env-switcher-button ${isProd ? "dev" : "prod"}`;

  const handleClick = () => {
    switchEnvironment();
    onEnvironmentSwitch();
  };

  return (
    <button className={buttonClass} onClick={handleClick}>
      {buttonText}
    </button>
  );
};

export default EnvSwitcher;
