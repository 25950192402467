import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { EnvironmentContext } from "../contexts/EnvironmentContext";
import config from "../config";
import EnvSwitcher from "./EnvSwitcher";

function AdminPage({ googleAuthToken }) {
  const [name, setName] = useState("");
  const [apiKeyTag, setApiKeyTag] = useState("");
  const [addtionalApiKeyTag, setAddtionalApiKeyTag] = useState("");
  const [newCustomerResult, setNewCustomerResult] = useState("");
  const [additionalKeyResult, setAdditionalKeyResult] = useState("");
  const [customerIdInput, setCustomerIdInput] = useState("");
  const [customers, setCustomers] = useState([]);

  const { env, switchEnvironment } = useContext(EnvironmentContext);

  useEffect(() => {
    if (env !== "dev") {
      switchEnvironment("dev");
    }
  }, []);
  const fetchCustomers = useCallback(async () => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/customers`, {
        headers: {
          Authorization: `Bearer ${googleAuthToken}`,
        },
      });
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  }, [googleAuthToken]);

  useEffect(() => {
    fetchCustomers();
  }, [env, fetchCustomers]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      env === "prod" &&
      !window.confirm(
        "Are you sure you want to add a new customer to the production environment?"
      )
    ) {
      return;
    }
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/add-customer`,
        {
          name,
          apiKeyTag,
        },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setNewCustomerResult(
        `Customer added successfully. API Key: ${response.data.apiKey}`
      );
    } catch (error) {
      setNewCustomerResult(
        `Error: ${error.response?.data?.error || error.message}`
      );
    }
  };

  async function addCustomerKey(customerId) {
    if (
      env === "prod" &&
      !window.confirm(
        "Are you sure you want to add a new customer key to the production environment?"
      )
    ) {
      return;
    }
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/add-customer-key`,
        {
          customerId,
          tag: addtionalApiKeyTag,
        },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setAdditionalKeyResult(`New API Key added: ${response.data.apiKey}`);
    } catch (error) {
      setAdditionalKeyResult(
        `Error: ${error.response?.data?.error || error.message}`
      );
    }
  }

  return (
    <div className="AdminPage">
      <h1>Admin Tools</h1>
      <EnvSwitcher onEnvironmentSwitch={switchEnvironment} currentEnv={env} />
      <div className="container">
        <h2>Add New Customer</h2>
        <form onSubmit={handleSubmit}>
          <label>
            <input
              type="text"
              placeholder="Customer Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </label>
          <label>
            <input
              type="text"
              placeholder="API Key Tag ('default' if left blank)"
              value={apiKeyTag}
              onChange={(e) => setApiKeyTag(e.target.value)}
            />
          </label>
          <button type="submit">Add Customer</button>
        </form>
        {newCustomerResult && <div className="result">{newCustomerResult}</div>}
      </div>
      <div className="container">
        <h2>Add Additional Key</h2>
        <div>
          <label>
            <select
              value={customerIdInput}
              onChange={(e) => setCustomerIdInput(e.target.value)}
            >
              <option value="">Select a customer</option>
              {customers.map((customer) => (
                <option key={customer.id} value={customer.id}>
                  {customer.name}
                </option>
              ))}
            </select>
          </label>
          <br />
          <label>
            <input
              type="text"
              placeholder="API Key Tag"
              value={addtionalApiKeyTag}
              onChange={(e) => setAddtionalApiKeyTag(e.target.value)}
            />
          </label>
          <br />
          <button
            type="submit"
            onClick={() => addCustomerKey(customerIdInput, addtionalApiKeyTag)}
            disabled={!customerIdInput || !addtionalApiKeyTag}
          >
            Add Customer Key
          </button>
        </div>
        {additionalKeyResult && (
          <div className="result">{additionalKeyResult}</div>
        )}
      </div>
    </div>
  );
}

export default AdminPage;
