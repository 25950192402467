import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { EnvironmentContext } from "../contexts/EnvironmentContext";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import config from "../config";
import EnvSwitcher from "./EnvSwitcher";

function CustomerCosts({ googleAuthToken }) {
  const [customerCosts, setCustomerCosts] = useState([]);
  const [weeklyCosts, setWeeklyCosts] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { env } = useContext(EnvironmentContext);

  const fetchCustomerCosts = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${config.apiBaseUrl}/customer-costs`, {
        headers: {
          Authorization: `Bearer ${googleAuthToken}`,
        },
      });
      setCustomerCosts(response.data);
    } catch (error) {
      setError(`Error fetching customer costs: ${error.message}`);
      console.error("Error fetching customer costs:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchWeeklyCosts = async (customerName) => {
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/weekly-customer-costs`,
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      const customerData = response.data.find(
        (c) => c.customer_name === customerName
      );
      setWeeklyCosts((prev) => ({
        ...prev,
        [customerName]: customerData?.weekly_costs || [],
      }));
    } catch (error) {
      console.error("Error fetching weekly costs:", error);
    }
  };

  const handleRowClick = (customerName) => {
    if (selectedCustomer === customerName) {
      setSelectedCustomer(null);
    } else {
      setSelectedCustomer(customerName);
      if (!weeklyCosts[customerName]) {
        fetchWeeklyCosts(customerName);
      }
    }
  };

  useEffect(() => {
    fetchCustomerCosts();
  }, [env, googleAuthToken]);

  if (loading) {
    return <div>Loading customer costs...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="CustomerCosts">
      <h1>Customer Costs</h1>
      <EnvSwitcher onEnvironmentSwitch={fetchCustomerCosts} currentEnv={env} />
      <div className="costs-container">
        <table>
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Total Cost</th>
              <th>Total Runs</th>
              <th>Average Cost per Run</th>
            </tr>
          </thead>
          <tbody>
            {customerCosts.map((customer) => (
              <React.Fragment key={customer.customer_name}>
                <tr
                  onClick={() => handleRowClick(customer.customer_name)}
                  style={{ cursor: "pointer" }}
                >
                  <td>{customer.customer_name}</td>
                  <td>${Number(customer.total_cost).toFixed(2)}</td>
                  <td>{customer.total_runs}</td>
                  <td>
                    $
                    {customer.total_runs > 0
                      ? (customer.total_cost / customer.total_runs).toFixed(2)
                      : "0.00"}
                  </td>
                </tr>
                {selectedCustomer === customer.customer_name &&
                  weeklyCosts[customer.customer_name] && (
                    <tr>
                      <td colSpan="4">
                        <div style={{ padding: "20px" }}>
                          <div
                            style={{
                              width: 800, // Match chart width
                              marginBottom: "20px",
                            }}
                          >
                            <h3 style={{ textAlign: "center" }}>
                              {customer.customer_name} - Weekly Costs and Runs
                            </h3>
                          </div>
                          <LineChart
                            width={800}
                            height={300}
                            data={weeklyCosts[customer.customer_name]}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="week"
                              tickFormatter={(value) => {
                                const year = parseInt(value.substring(0, 4));
                                const week = parseInt(value.substring(6));
                                const date = new Date(
                                  year,
                                  0,
                                  1 + (week - 1) * 7
                                );
                                return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}`;
                              }}
                            />
                            <YAxis
                              yAxisId="cost"
                              orientation="left"
                              stroke="#8884d8"
                              label={{
                                value: "Cost ($)",
                                angle: -90,
                                position: "insideLeft",
                                offset: 0,
                                style: {
                                  textAnchor: "middle",
                                },
                              }}
                              tickFormatter={(value) => {
                                const formatted = Number(value).toFixed(2);
                                return `$${formatted.replace(/\.?0+$/, "")}`;
                              }}
                            />
                            <YAxis
                              yAxisId="runs"
                              orientation="right"
                              stroke="#82ca9d"
                              label={{
                                value: "Number of Runs",
                                angle: 90,
                                position: "insideRight",
                                offset: 0,
                                style: {
                                  textAnchor: "middle",
                                },
                              }}
                            />
                            <Tooltip
                              formatter={(value, name) => {
                                if (name === "Cost ($)") {
                                  return [`$${Number(value).toFixed(2)}`, name];
                                }
                                return [value, name];
                              }}
                            />
                            <Line
                              type="monotone"
                              dataKey="cost"
                              stroke="#8884d8"
                              name="Cost ($)"
                              yAxisId="cost"
                            />
                            <Line
                              type="monotone"
                              dataKey="runs"
                              stroke="#82ca9d"
                              name="Runs"
                              yAxisId="runs"
                            />
                          </LineChart>
                        </div>
                      </td>
                    </tr>
                  )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CustomerCosts;
