import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Runs from "./components/Runs";
import Agents from "./components/Agents";
import LLMCalls from "./components/LlmCalls";
import GoogleAuth from "./GoogleAuth";
import AdminPage from "./components/AdminPage";
import CustomerCosts from "./components/CustomerCosts";
import "./App.css";
import { EnvironmentProvider } from "./contexts/EnvironmentContext";
import config from "./config";

function App() {
  const [authState, setAuthState] = useState(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? "authenticated" : "unauthenticated";
  });
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [selectedRun, setSelectedRun] = useState(null);
  const [selectedObject, setselectedObject] = useState(null);
  const [page, setPage] = useState(1);
  const [currentView, setCurrentView] = useState("Agents");

  const handleLogout = useCallback(() => {
    setUser(null);
    setAuthState("unauthenticated");
    setIsAdmin(false);
    localStorage.removeItem("user");
    // Clear any other relevant state or storage
  }, []);

  const isAdminEmail = useCallback((email) => {
    const adminEmails = process.env.REACT_APP_ADMIN_EMAILS
      ? process.env.REACT_APP_ADMIN_EMAILS.split(",")
      : [];
    return adminEmails.includes(email);
  }, []);

  useEffect(() => {
    const validateToken = async () => {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        const userObject = JSON.parse(storedUser);
        if (userObject.token) {
          try {
            const response = await fetch(
              `${config.apiBaseUrl}/validate-token`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ token: userObject.token }),
              }
            );

            // Check if the response is OK and contains JSON
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }

            const contentType = response.headers.get("content-type");
            if (!contentType || !contentType.includes("application/json")) {
              throw new Error("Oops! We haven't received a JSON response");
            }

            const data = await response.json();
            if (data.valid) {
              // Preserve the existing token instead of overwriting it
              setUser((prevUser) => ({
                ...prevUser,
                ...data.user,
                token: userObject.token,
              }));
              setAuthState("authenticated");
              setIsAdmin(isAdminEmail(data.user.email));
            } else {
              handleLogout();
            }
          } catch (error) {
            console.error("Error validating token:", error.message);
            handleLogout();
          }
        } else {
          handleLogout();
        }
      } else {
        setAuthState("unauthenticated");
      }
    };

    validateToken();
  }, [handleLogout, isAdminEmail]);

  const handleLoginSuccess = useCallback(
    (userObject) => {
      const { email, token } = userObject; // Destructure to get email and token
      if (email) {
        setUser({ email, token }); // Store email and token in state
        setAuthState("authenticated");
        setIsAdmin(isAdminEmail(email));
        localStorage.setItem("user", JSON.stringify({ email, token }));
      } else {
        console.error("User object does not have an email property");
      }
    },
    [isAdminEmail]
  );

  const handleLoginFailure = (error) => {
    console.error("Login failed:", error);
  };

  const handleRunClick = useCallback((run) => {
    if (run) {
      setSelectedRun(run);
      setselectedObject(null);
    } else {
      console.error("Selected run is undefined");
    }
  }, []);

  const handleAgentClick = (agent) => {
    setselectedObject(agent);
  };

  const handleRefresh = useCallback(() => {
    setSelectedRun(null);
    setselectedObject(null);
  }, []);

  const handleViewChange = (newView) => {
    setCurrentView(newView);
  };

  useEffect(() => {
    setselectedObject(null);
  }, [currentView]);

  return (
    <EnvironmentProvider>
      <Router>
        <div className="App">
          {authState === "authenticated" ? (
            <>
              <Routes>
                <Route
                  path="/admin"
                  element={
                    isAdmin ? (
                      <AdminPage googleAuthToken={user.token} />
                    ) : (
                      <div>Access Denied</div>
                    )
                  }
                />
                <Route
                  path="/customers"
                  element={<CustomerCosts googleAuthToken={user.token} />}
                />
                <Route
                  path="/"
                  element={
                    <div className="app-container">
                      <Runs
                        onRunClick={handleRunClick}
                        selectedRun={selectedRun}
                        googleAuthToken={user.token}
                        page={page}
                        setPage={setPage}
                        onRefresh={handleRefresh}
                        onLogout={handleLogout}
                      />
                      <div className="Agents">
                        {selectedRun ? (
                          <Agents
                            run={selectedRun}
                            onAgentClick={handleAgentClick}
                            selectedObject={selectedObject}
                            googleAuthToken={user.token}
                            onViewChange={handleViewChange}
                          />
                        ) : (
                          <div className="agents-placeholder">
                            Select a run to see agents
                          </div>
                        )}
                      </div>
                      <div className="LLMCalls">
                        {selectedObject ? (
                          <LLMCalls
                            agent={selectedObject}
                            googleAuthToken={user.token}
                            currentView={currentView}
                          />
                        ) : (
                          <div className="llm-calls-placeholder">
                            {currentView === "observables"
                              ? "Select an observable to view details"
                              : currentView === "outputs"
                                ? "Select an output to view details"
                                : currentView === "errors"
                                  ? "Select an error to view details"
                                  : currentView === "agents"
                                    ? "Select an agent to see LLM calls and actions"
                                    : ""}
                          </div>
                        )}
                      </div>
                    </div>
                  }
                />
              </Routes>
            </>
          ) : authState === "unauthenticated" ? (
            <div className="auth-container">
              <GoogleAuth
                onLoginSuccess={handleLoginSuccess}
                onLoginFailure={handleLoginFailure}
              />
            </div>
          ) : null}
        </div>
      </Router>
    </EnvironmentProvider>
  );
}

export default App;
